import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconWebLogo } from '../svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultFormData, setShowForgotPassword, setShowForgotPasswordMsg, setShowLogin } from '../store/auth/reducer';

const GeneralTopMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { showForgotPassword, showForgotPasswordMsg } = useSelector((state) => state.auth);
  const { pageTitle } = useSelector((state) => state.general);
  const gotoLoginPage = () => {
    if (showForgotPassword === true || showForgotPasswordMsg === true) {
      dispatch(setShowLogin(true));
      dispatch(setShowForgotPassword(false));
      dispatch(setShowForgotPasswordMsg(false));
      dispatch(setDefaultFormData());
    } else {
      navigate('/');
    }
  }

  return (
    <div className='container-fluid bg-custom-white general-heading-logo d-flex justify-content-between align-items-center'>

      <IconWebLogo width={150} height={60} customClass='cursor-pointer' onIconClick={gotoLoginPage} />

      {pageTitle !== 'Login || Madini Chapaa' && (<span
        className='btn btn-orange-primary cursor-pointer rounded'
        onClick={gotoLoginPage}
      >
        Sign in
      </span>)}
    </div>
  );
};

export default GeneralTopMenu;

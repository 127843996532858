import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { setDisabledButton, setShowCamera } from '../store/general/reducer';
import { isMobileScreen } from '../helper/common-helper';
import * as Yup from 'yup';
import { setOtpFormData, setShowVerifyOtp } from '../store/auth/reducer';
import { actionSendOtp, actionVerifyOtp } from '../store/auth/actions';
import { Spinner } from '../svg-icons';

const VerifyOtp = ({ userId = null,verifyTrade=false }) => {
   const { otpFormData, showVerifyOtp } = useSelector((state) => state.auth);
   const { disabledButton } = useSelector((state) => state.general);
   const dispatch = useDispatch();
   const [errors, setErrors] = useState({});
   const [timer, setTimer] = useState(15); // Timer state
   const [isResendAvailable, setIsResendAvailable] = useState(false); // Resend OTP availability

   const schema = Yup.object().shape({
      otp: Yup.string().required('OTP is required'),
   });

   const validateField = async (name, value) => {
      try {
         await Yup.reach(schema, name).validate(value);
         setErrors({ ...errors, [name]: '' });
      } catch (validationError) {
         setErrors({ ...errors, [name]: validationError.message });
      }
   };

   const validateForm = async () => {
      try {
         await schema.validate(otpFormData, { abortEarly: false });
         setErrors({});
         return true;
      } catch (validationErrors) {
         const newErrors = validationErrors.inner.reduce((acc, err) => {
            acc[err.path] = err.message;
            return acc;
         }, {});
         setErrors(newErrors);
         return false;
      }
   };

   const handleSubmit = async (e) => {
      e.preventDefault();

      const isValid = await validateForm();

      if (isValid) {
         dispatch(setDisabledButton(true));
         dispatch(actionVerifyOtp(otpFormData, userId,verifyTrade));
      } else {
         console.log('Form has errors:', errors);
      }
   };

   const handleChange = (e) => {
      const { name, value } = e.target;
      let data = { ...otpFormData, [name]: value };
      dispatch(setOtpFormData(data));
      validateField(name, value);
   };

   // Timer countdown logic
   useEffect(() => {
      let interval;
      if (showVerifyOtp) {
         setTimer(15); // Reset timer every time dialog is shown
         setIsResendAvailable(false); // Reset resend availability

         // Start timer countdown when dialog is visible
         interval = setInterval(() => {
            setTimer((prevTimer) => {
               if (prevTimer === 1) {
                  clearInterval(interval);
                  setIsResendAvailable(true); // Enable resend OTP after 15 seconds
                  return 0;
               }
               return prevTimer - 1;
            });
         }, 1000);
      } else {
         clearInterval(interval); // Clear interval when dialog is closed
      }

      return () => clearInterval(interval); // Cleanup on component unmount
   }, [showVerifyOtp]);



   const handleResendOtp = () => {
      // Clear any existing timer intervals to avoid multiple timers
      setTimer(15); // Reset timer to 15 seconds
      setIsResendAvailable(false); // Disable resend until the countdown finishes

      // Dispatch the action to resend the OTP
      dispatch(actionSendOtp(otpFormData.phone_number));

      // Restart the timer interval
      const interval = setInterval(() => {
         setTimer((prevTimer) => {
            if (prevTimer === 1) {
               clearInterval(interval);
               setIsResendAvailable(true); // Enable resend OTP after 15 seconds
               return 0;
            }
            return prevTimer - 1;
         });
      }, 1000);
   };

   return (
      <Dialog
         header="Verify OTP"
         visible={showVerifyOtp}
         style={{ width: isMobileScreen ? '75vw' : '25vw' }}
         onHide={() => dispatch(setShowVerifyOtp(false))}
      >
         <form onSubmit={handleSubmit} noValidate>
            <div className="row mb-4">
               <div className="col-12">
                  <div className="form-group">
                     <label>Enter OTP</label>
                     <input
                        type="number"
                        className="form-control"
                        name="otp"
                        value={otpFormData.otp}
                        onChange={handleChange}
                        placeholder="Enter the OTP"
                     />
                     {errors.otp && <span className="error-message">{errors.otp}</span>}
                  </div>
               </div>
               <div className="col-6 mt-3">
                  <button
                     type="submit"
                     className="btn btn-orange-primary"
                     disabled={disabledButton}
                  >
                     {disabledButton ? <Spinner /> : 'Verify OTP'}
                  </button>
               </div>
               <div className="col-6 mt-3 text-end">
                  {timer > 0 ? (
                     <span>{timer} seconds</span> // Show countdown timer
                  ) : (
                     isResendAvailable && (
                        <span
                           className="cursor-pointer text-primary text-muted-one text-underline fnt-size-16"
                           onClick={handleResendOtp}
                        >
                           Resend OTP
                        </span>
                     )
                  )}
               </div>

            </div>
         </form>
      </Dialog>
   );
};

export default VerifyOtp;

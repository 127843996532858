// Import files and libraries
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import Accordion from 'react-bootstrap/Accordion';


//others
import { IconBack, IconEdit, Spinner } from '../../../svg-icons';

// reducer
import { setFormModel, setFormData, setSubCountryList, setDivisionList, setLocationList, setSubLocationList } from '../../../store/location/reducer';
import { setDisabledButton, updateLoaderStatus } from '../../../store/general/reducer';

// action
import { actionCreate, actionGetDDLocationList, actionGetDivisionList, actionGetSubLocationList, actionUpdate } from '../../../store/location/action'

// custom component
import { PERMISSION_KEY, PERMISSION_PAGE_KEY, validatePermission } from '../../../config/constant';

import FormStatus from '../../../component/form-status';

const LocationFormModel = () => {
    // create a dispatch
    const dispatch = useDispatch();

    // create a state
    const [errors, setErrors] = useState({});
    const [editForm, setEditForm] = useState(false);

    const { formData, updateId, formModel, countryList, countyList, divisionList, ddLocationList, subLocationList } = useSelector((state) => state.location);
    const { disabledButton } = useSelector((state) => state.general);
    const { agentList } = useSelector((state) => state.agent);
    const { defaultUserPermission } = useSelector((state) => state.auth);

    useEffect(() => {
        setErrors({});
        setEditForm(updateId ? false : true);
    }, [formModel]);


    // form validation schema
    let schema = Yup.object().shape({
        // location_name: Yup.string()
        //     .max(50, 'Location name must be at most 50 characters long')
        //     .required('Location name is required'),
        address: Yup.string()
            .trim()
            .max(255, 'Address must be at most 255 characters long')
            .test('not-only-numbers', 'Address cannot be only numbers', (value) => {
                return !/^[0-9]+$/.test(value); // Ensure the address is not only numbers
            })
            .test('contains-letter-or-number', 'Address must contain at least one letter', (value) => {
                return /[a-zA-Z]/.test(value); // Ensure there is at least one letter or number
            })
            .required('Address is required'),
        county: Yup.string()
            .required('County is required'),
        division: Yup.string()
            .required('Division is required'),
        // sub_location: Yup.string()
        //     .required('Sub location is required'),
        country: Yup.string()
            .required('Country is required'),
        location: Yup.string()
            .required('Location is required'),
        agent_id: Yup.string()
            .required('Agent is required'),
    });

    // form modal close
    const closeModal = async () => {
        dispatch(setFormModel(false));
    };

    //validate the fields
    const validateField = async (name, value) => {
        try {
            await Yup.reach(schema, name).validate(value);
            setErrors({ ...errors, [name]: '' });
        } catch (validationError) {
            setErrors({ ...errors, [name]: validationError.message });
        }
    };

    //validate the form
    const validateForm = async () => {
        try {
            await schema.validate(formData, { abortEarly: false });
            setErrors({});
            return true;
        } catch (validationErrors) {
            const newErrors = validationErrors.inner.reduce((acc, err) => {
                acc[err.path] = err.message;
                return acc;
            }, {});
            setErrors(newErrors);
            return false;
        }
    };

    //handling the form submition
    const handleSubmit = async (e) => {

        // prevent the form submission
        e.preventDefault();

        // get the form status
        const isValid = await validateForm();

        if (isValid) { // check the form is valid or invalid
            dispatch(setDisabledButton(true));
            dispatch(updateLoaderStatus(true));
            const location_name = subLocationList.filter(sl => sl.id === formData.sub_location);
            const formFinalData = { ...formData, location_name: location_name.length > 0 ? location_name[0].title : "" };

            if (updateId) { //check the form action update or create
                // update data with update id
                dispatch(actionUpdate(formFinalData, updateId));
            } else {
                // create form data
                dispatch(actionCreate(formFinalData));
            }
        } else {
            console.log('Form has errors:', errors);
        }
    };

    // handling the input changes
    const handleChange = async (e) => {
        let { name, value } = e.target;

        let data = {
            ...formData,
            [name]: value,
        };
        switch (name) {
            case 'county':
                dispatch(setDivisionList([]));
                dispatch(setLocationList([]));
                dispatch(setSubLocationList([]));
                dispatch(actionGetDivisionList(value));
                data = {
                    ...data,
                    division: "",
                    location: "",
                    sub_location: ""
                }
                break;
            case 'division':
                dispatch(setLocationList([]));
                dispatch(setSubLocationList([]));
                dispatch(actionGetDDLocationList(value));
                data = {
                    ...data,
                    location: "",
                    sub_location: ""
                }
                break;
            case 'location':
                dispatch(setSubLocationList([]));
                dispatch(actionGetSubLocationList(value));
                data = {
                    ...data,
                    sub_location: ""
                }
                break;
        }

        dispatch(setFormData(data));
        validateField(name, value);
    };

    return (
        <>
            <form onSubmit={handleSubmit} noValidate>
                <div className='row'>
                    <div className="col-md-10 mb-3">
                        <div className='d-flex align-items-center'>
                            <IconBack
                                width={32}
                                height={32}
                                onIconClick={closeModal}
                                customClass='cursor-pointer'
                            />
                            <h3 className='ms-2 flex-shrink-1 mb-0'>{updateId ? editForm ? 'Update Location' : 'View Location' : 'Create New Location'}</h3>
                        </div>
                    </div>
                    {updateId && !editForm && defaultUserPermission && validatePermission(defaultUserPermission, PERMISSION_PAGE_KEY.LOCATION, PERMISSION_KEY.UPDATE) && (
                        <div className="col-md-2 mb-3 text-end">
                            <button
                                className="btn  btn-purple-primary me-3"
                                type='button'
                                onClick={e => setEditForm(true)}
                            >
                                <IconEdit customClass='text-white' /> Edit
                            </button>
                        </div>
                    )}
                </div>

                <div className="col-12 mt-3">
                    <Accordion defaultActiveKey={['0']} alwaysOpen className='custom-accordiant'>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><strong>Location information</strong></Accordion.Header>
                            <Accordion.Body>
                                <div className="row">
                                    <div className="col-md-4 d-none">
                                        <div className="form-group mb-3">
                                            <label>Location Name</label>
                                            <input type="text" className="form-control "
                                                name="location_name"
                                                disabled={!editForm}
                                                value={formData.location_name}
                                                onChange={handleChange}
                                                placeholder='Enter the location name'
                                            />
                                            {errors.location_name && <span className="error-message">{errors.location_name}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group mb-3">
                                            <label>Default Agent</label>
                                            <Dropdown
                                                value={formData.agent_id}
                                                disabled={!editForm}
                                                onChange={handleChange}
                                                options={agentList}
                                                optionLabel="full_name"
                                                optionValue="id"
                                                placeholder="Select a agent"
                                                className="w-100 custom-single-dropdown"
                                                name='agent_id'
                                            />
                                            {errors.agent_id && <span className="error-message">{errors.agent_id}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-8">
                                        <div className="form-group mb-3">
                                            <label>Address</label>
                                            <textarea className="form-control "
                                                name="address"
                                                disabled={!editForm}
                                                value={formData.address}
                                                onChange={handleChange}
                                                placeholder={`Address Line 1\nAddress Line 2\nCity\nState\nCountry\nPincode`}
                                                rows={6}
                                            >
                                                {formData.address}
                                            </textarea>
                                            {errors.address && <span className="error-message">{errors.address}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <div className="form-group mb-3">
                                            <label>County</label>
                                            <Dropdown
                                                value={formData.county}
                                                disabled={!editForm}
                                                onChange={handleChange}
                                                options={countyList}
                                                optionLabel="title"
                                                optionValue="id"
                                                placeholder="Select a county"
                                                className="w-100 custom-single-dropdown"
                                                name='county'
                                            />
                                            {errors.county && <span className="error-message">{errors.county}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group mb-3">
                                            <label>Division</label>
                                            <Dropdown
                                                value={formData.division}
                                                disabled={!editForm}
                                                onChange={handleChange}
                                                options={divisionList}
                                                optionLabel="title"
                                                optionValue="id"
                                                placeholder="Select a division"
                                                className="w-100 custom-single-dropdown"
                                                name='division'
                                            />
                                            {errors.division && <span className="error-message">{errors.division}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <div className="form-group mb-3">
                                            <label>Location</label>
                                            <Dropdown
                                                value={formData.location}
                                                disabled={!editForm}
                                                onChange={handleChange}
                                                options={ddLocationList}
                                                optionLabel="title"
                                                optionValue="id"
                                                placeholder="Select a location"
                                                className="w-100 custom-single-dropdown"
                                                name='location'
                                            />
                                            {errors.location && <span className="error-message">{errors.location}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group mb-3">
                                            <label>Sub Location</label>
                                            <Dropdown
                                                value={formData.sub_location}
                                                disabled={!editForm}
                                                onChange={handleChange}
                                                options={subLocationList}
                                                optionLabel="title"
                                                optionValue="id"
                                                placeholder="Select a sub location"
                                                className="w-100 custom-single-dropdown"
                                                name='sub_location'
                                            />
                                            {/* {errors.sub_location && <span className="error-message">{errors.sub_location}</span>} */}
                                        </div>
                                    </div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <div className="form-group mb-3">
                                            <label>Country</label>
                                            <Dropdown
                                                value={formData.country}
                                                disabled={true}
                                                // onChange={handleChange}
                                                options={countryList}
                                                optionLabel="title"
                                                optionValue="id"
                                                placeholder="Select a country"
                                                className="w-100 custom-single-dropdown"
                                                name='country'
                                            />
                                            {errors.country && <span className="error-message">{errors.country}</span>}
                                        </div>
                                    </div>

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className='col-12 mb-3'>
                    <FormStatus
                        onStatusClick={handleChange}
                        active={formData.active}
                        id={updateId}
                        editForm={editForm}
                    />
                </div>
                <Divider />
                <div className="col-12 text-end">
                    <div className="form-group mb-3">
                        {editForm && (
                            <>
                                <button
                                    className="btn  btn-purple-primary-outline me-3"
                                    type='button'
                                    onClick={closeModal}
                                >
                                    Cancel
                                </button>
                                <button
                                    disabled={disabledButton}
                                    className="btn  btn-purple-primary"
                                    type='submit'
                                >
                                    {disabledButton ? <Spinner /> : null} {updateId ? 'Update' : 'Create'}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </form >
        </>
    );
};


export default LocationFormModel;

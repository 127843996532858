import moment from 'moment-timezone';
// import moment from 'moment';

// moment(new Date()).tz(moment.tz.guess());

//display
export const getDisplayDateOnly = (date) => {
    return moment(date).format('DD/MM/YYYY');
};
export const getDisplayTimeOnly = (date) => {
    return moment(date).format('hh:mm a');
};
export const getDisplayDateTime = (date) => {
    return moment.utc(date,"YYYY-MM-DD HH:mm:ss")
    .local()
    // .tz("Africa/Nairobi")
    .format('DD/MM/YYYY hh:mm a');
};

//database
export const getDatabaseDateOnly = (date) => {
    return moment(date).format('YYYY-MM-DD');
};
export const getDatabaseTimeOnly = (date) => {
    return moment(date).format('HH:mm:ss');
};
export const getDatabaseDateTime = (date) => {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
};

export const getDisplayTimeParase = (time) => {
    return moment(time,"HH:mm:ss").format('YYYY-MM-DD HH:mm:ss');
};
export const getDisplayTimeParaseOnly = (time) => {
    return moment(time,"HH:mm:ss").format('hh:mm a');
};


export const getDateISO = (date) => {
    return moment(date).toISOString();
};

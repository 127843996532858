//reducer
import { setUpdateId, setFormData, setFormModel, setNationalIdResponse, setDefaultFormData, setShowRegisterationSuccessMsg, setCustomerWallet, setSupportDetails, setNationalIDValidation, setVerifiedPhoneNumber, setPhoneNumberValidation, setOldPhoneNumber } from "./reducer";
import { updateLoaderStatus, setTableLoader, setDisabledButton, setBulkSupportingDocumentData, setProDocDefault, setFileData, setChangePasswordModel } from '../general/reducer';
import { setTableData } from "../table/reducer";

// others
import { HTTP_STATUS_CODE, PERMISSION_KEY, PERMISSION_PAGE_KEY, TABLES, TOAST_TYPE, validatePermission } from '../../config/constant';
import { showToast } from "../../helper/toast-service-helper";

//services
import customerService from '../../services/customer';
import customerWalletService from '../../services/customer-wallet';
import { formatFileSize, handingApiErrors, truncateFileName } from "../../helper/common-helper";
import { actionGetFileById } from "../general/action";
import { actionGetAgentList } from "../agent/action";
import { actionGetOrgLocationList } from "../organization/action";
import { getDisplayDateOnly } from "../../helper/date-helper";
import { setOrgLocationList } from "../organization/reducer";

export const actionChangePassword = (data) => {
    return async (dispatch, getState) => {
        const response = await customerService.changePassword(data);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        dispatch(setChangePasswordModel(false));
        showToast(TOAST_TYPE.SUCCESS, 'Password Reset Done Successfully', 'top-right');
    }
}
// export user create action
export const actionCreate = (data, from = 'register') => {
    return async (dispatch, getState) => {

        const response = await customerService.create(data);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        await dispatch(actionGetAll());
        await dispatch(setFormModel(false));
        // clear the form details
        dispatch(setDefaultFormData());
        dispatch(setProDocDefault());
        // remove the update id
        dispatch(setUpdateId(null));
        if (from === 'register') {
            dispatch(setShowRegisterationSuccessMsg(true));
        } else {
            showToast(TOAST_TYPE.SUCCESS, 'Successfully created', 'top-right');
        }

    }
}

// export user update action
export const actionUpdate = (data, id, from = '') => {
    return async (dispatch, getState) => {

        const response = await customerService.update(data, id);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        await dispatch(actionGetAll());
        await dispatch(setFormModel(false));

        showToast(TOAST_TYPE.SUCCESS, from === 'customer' ? 'Profile Update Completed Successfully' : "Successfully updated", 'top-right');

    }
}

// export user get all action
export const actionGetAll = () => {
    return async (dispatch, getState) => {

        const { defaultUserPermission } = getState().auth;
        if (!validatePermission(defaultUserPermission, PERMISSION_PAGE_KEY.CUSTOMER, PERMISSION_KEY.READ)) {
            return;
        }

        let getParams = getState().table[TABLES.USER];
        const data = {
            offset: getParams.offset,
            limit: getParams.limit
        };
        const response = await customerService.getAll(data);
        dispatch(setTableLoader(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        await dispatch(setTableData({
            data: response.data.data.customers,
            totalRecord: response.data.data.total_count,
            tableKey: TABLES.USER
        }));

    }
}

// export user get by id action
export const actionGetById = (id) => {
    return async (dispatch, getState) => {

        const response = await customerService.getById(id);
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        const data = response.data.data.customer;

        let supportingDocuments = {};
        if (data?.supporting_documents && (data.supporting_documents).length > 0) {
            data.supporting_documents.forEach(file => {

                let inx = 'file_' + Math.floor(Math.random() * (999 - 100 + 1)) + 100;

                let fileDetails = {
                    ...file,
                    size: formatFileSize(file.file_size),
                    name: truncateFileName(file.original_name)
                };

                supportingDocuments[inx] = fileDetails;
            });

            dispatch(setBulkSupportingDocumentData(supportingDocuments));

        }

        await dispatch(actionGetOrgLocationList(data.organization_id));

        const orgLocation = getState().organization.orgLocationList;
        
        const existLocation = orgLocation.filter(orgLocation => orgLocation.id === data.location_id);
        if (existLocation.length < 1) {
            const existingValue = {
                "id": data.location_id,
                "location_name": data.location_name,
                "agent_id": null,
                "organization_id": null
            };
            await dispatch(setOrgLocationList([...orgLocation, existingValue]));
        }

        await dispatch(actionGetAgentList(data.organization_id));
        await dispatch(setUpdateId(id));
        await dispatch(setFormData({
            first_name: data.first_name,
            middle_name: data.middle_name,
            last_name: data.last_name,
            national_id: data.national_id,
            email: data.email,
            phone_number: data.phone_number,
            citizen_ship: data.citizen_ship,
            cladfy_request_id: data.cladfy_request_id,
            serial_number: data.serial_number,
            dob: data.dob,
            gender: data.gender,
            marital_status: data.marital_status,
            occupation: data.occupation,
            nominee_name: data.nominee_name,
            nominee_relationship: data.nominee_relationship,
            nominee_phone_number: data.nominee_phone_number,
            address: data.address,
            remarks: data.remarks,
            location_id: data.location_id,
            organization_id: data.organization_id,
            agent_id: data.agent_id,
            active: data.active,
            wallet_payment: 0,
            verified_phone_number: data.verified_phone_number
        }));
        await dispatch(setPhoneNumberValidation(data.verified_phone_number == '1' ? true : false));
        await dispatch(setNationalIDValidation(true));
        await dispatch(setOldPhoneNumber(data.phone_number));
        //await dispatch(setOldNationalId(data.national_id));

        await dispatch(setSupportDetails({
            agent_name: `${data.agent_first_name} ${data.agent_middle_name} ${data.agent_last_name}`,
            location_name: data.location_name,
            agent_phone_number: data.agent_phone_number,
            agent_email: data.agent_email
        }
        ));

        if (data.profile) {
            await dispatch(actionGetFileById(data.profile.slug, 'profile'));
        } else {
            await dispatch(setFileData({ name: 'profile', value: null }));

        }
        await dispatch(updateLoaderStatus(false));
        await dispatch(setFormModel(true));

    }

}

export const actionValidateNationalId = (nationalId) => {
    return async (dispatch, getState) => {
        await dispatch(updateLoaderStatus(true));
        const response = await customerService.validateNationalId(nationalId);
        await dispatch(updateLoaderStatus(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }

        if (typeof response.data.data.cladfy.data !== 'undefined' && (response.data.data.cladfy.data).length !== 0) {
            if (response.data.data.cladfy.data.valid === false) {
                showToast(TOAST_TYPE.ERROR, "Invalid National ID", "top-right");
                return;
            }
            dispatch(setNationalIdResponse(response.data.data.cladfy.data));
            dispatch(setNationalIDValidation(true));
        } else {
            showToast(TOAST_TYPE.ERROR, "Please contact technical team", "top-right");
            dispatch(setDefaultFormData());
        }

    }
}

export const actionGetCustomerWallet = (customerId) => {
    return async (dispatch, getState) => {
        await dispatch(updateLoaderStatus(true));
        const response = await customerWalletService.getById(customerId);
        await dispatch(updateLoaderStatus(false));
        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }
        dispatch(setCustomerWallet(response.data.data.customer_wallet));

    }
}
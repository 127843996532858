// Import files and libraries
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';

import * as Yup from 'yup';

// action
import { IconBack, IconDownload, IconErrorWarning, IconSuccessCheckMark, IconTime, Spinner } from '../../../svg-icons';
import { DEFAULT_USER_ROLE, ENVIRONMENT, ORDER_STATUS, PERMISSION_KEY, PERMISSION_PAGE_KEY, validatePermission } from '../../../config/constant';
import { actionDisbursal, actionGetById, actionReassignOrder } from '../../../store/trade/action';
import TradeItemTable from '../../../component/trade-item-table';
import { useNavigate } from 'react-router-dom';
import GetOrderStatus from '../../../component/order-status';
import { getDatabaseDateOnly, getDatabaseTimeOnly, getDisplayDateOnly, getDisplayDateTime, getDisplayTimeOnly, getDisplayTimeParase, getDisplayTimeParaseOnly } from '../../../helper/date-helper';
import ItemDetails from '../../../component/item-details';
import { setClearTradeState, setCurrentItem, setPaymentDisbursal, setPaymentDisbursalMsg, setReAssignFormData } from '../../../store/trade/reducer';
import PermissionDenied from '../../../component/permission-denied';
import { setClearGeneralState, setDisabledButton, updateLoaderStatus } from '../../../store/general/reducer';

import { currencyFormatValue, isMobileScreen } from '../../../helper/common-helper';
import { actionGetAgentList } from '../../../store/agent/action';
import { Calendar } from 'primereact/calendar';
import { actionGetOrganizationList } from '../../../store/organization/action';
import { actionSendOtp } from '../../../store/auth/actions';
import VerifyOtp from '../../../component/verify-otp';


const TradingDetails = () => {
    // create a dispatch
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // create a state
    const [verifyPhoneNumber, setVerifyPhoneNumber] = useState(false);
    const [errors, setErrors] = useState(false);
    const [key, setKey] = useState('items');


    const {
        defaultUserPermission, userData
    } = useSelector((state) => state.auth);

    const {formData,
        tradeDetails,
        paymentDisbursal,
        paymentDisbursalMsg,
        reAssignFormData
    } = useSelector((state) => state.trade);

    const {
        disabledButton,
        urlParams
    } = useSelector((state) => state.general);

    const { organizationList } = useSelector((state) => state.organization);
    const { agentList } = useSelector((state) => state.agent);


    // form validation schema
    let schema = Yup.object().shape({
        organization_id: Yup.string()
            .required('Organization is required'),
        agent_id: Yup.string()
            .required('Agent is required'),
        scheduled_date: Yup.string()
            .required('Pick-up Date is required'),
        scheduled_time: Yup.string()
            .required('Pick-up Time is required'),

    });

    //validate the fields
    const validateField = async (name, value) => {
        try {
            await Yup.reach(schema, name).validate(value);
            setErrors({ ...errors, [name]: '' });
        } catch (validationError) {
            setErrors({ ...errors, [name]: validationError.message });
        }
    };

    //validate the form
    const validateForm = async () => {
        try {
            await schema.validate(reAssignFormData, { abortEarly: false });
            setErrors({});
            return true;
        } catch (validationErrors) {
            const newErrors = validationErrors.inner.reduce((acc, err) => {
                acc[err.path] = err.message;
                return acc;
            }, {});
            setErrors(newErrors);
            return false;
        }
    };

    // handling the input changes
    const handleChange = async (e) => {
        let { name, value } = e.target;
        if (e.target) {
            ({ name, value } = e.target);
        } else {
            name = e.target.name;
            value = e.value; // Calendar component passes the value as Date object
        }
    
        // If the name is 'scheduled_time' (pick-up time)
        if (name === 'scheduled_time') {
            const selectedTime = new Date(value);
            const currentTime = new Date();
            const selectedDate = new Date(reAssignFormData.scheduled_date); // Getting the selected date
    
            // Set the allowed time range for future dates: 9:00 AM to 9:00 PM
            const startTime = new Date();
            startTime.setHours(9, 0, 0, 0); // 9:00 AM today
            
            const endTime = new Date();
            endTime.setHours(21, 0, 0, 0); // 9:00 PM today
    
            const minAllowedTime = new Date(currentTime);
            minAllowedTime.setMinutes(currentTime.getMinutes() + 30); // 30 minutes from now
    
            // Check if the selected date is today (same date, ignoring time)
            if (selectedDate.toDateString() === currentTime.toDateString()) {
                // For today, set the time to current time + 30 minutes
                const timeWith30Minutes = new Date(currentTime);
                timeWith30Minutes.setMinutes(currentTime.getMinutes() + 30);
    
                // If the selected time is before 30 minutes from current time, adjust it
                if (selectedTime < minAllowedTime) {
                    value = timeWith30Minutes; // Set time to current time + 30 minutes
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        scheduled_time: "Pick-up time for today must be at least 30 minutes from now."
                    }));
                } else {
                    // Clear the error if the selected time is valid
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        scheduled_time: ""
                    }));
                }
            } else {
                // If the selected date is in the future, ensure the time is within 9:00 AM to 9:00 PM
                if (selectedTime < startTime) {
                    value = startTime; // Set time to 9:00 AM if it's before
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        scheduled_time: "Pick-up time must be between 9:00 AM and 9:00 PM."
                    }));
                } else if (selectedTime >= endTime) {
                    value = endTime; // Set time to 9:00 PM if it's after
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        scheduled_time: "Trading hours are over for today; please adjust the pickup date and time."
                    }));
                } else {
                    // Clear the error if the time is within range
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        scheduled_time: ""
                    }));
                }
            }
        }
        
        const data = {
            ...reAssignFormData,
            [name]: value,
        };
    
        if (name === 'organization_id') {
            dispatch(actionGetAgentList(value));
        }
    
        dispatch(setReAssignFormData(data));
        validateField(name, value);
    };
    




    // On page function call get the page data and if any table action changes call the api
    useEffect(() => {
        if (urlParams?.view && urlParams?.type && urlParams.type === 'order') {
            dispatch(setClearGeneralState());
            dispatch(actionGetById(urlParams.view));
            // setDefaultOrg();
            dispatch(actionGetOrganizationList());
        }
    }, [urlParams]);



    //handling the form submition
    const handleSubmit = async (e) => {

        // prevent the form submission
        e.preventDefault();

        // get the form status
        const isValid = await validateForm();

        if (isValid) { // check the form is valid or invalid
            dispatch(setDisabledButton(true));
            dispatch(updateLoaderStatus(true));
            let finalData = {
                ...reAssignFormData,
                scheduled_date: getDatabaseDateOnly(reAssignFormData.scheduled_date),
                scheduled_time: getDatabaseTimeOnly(reAssignFormData.scheduled_time)
            }
            dispatch(actionReassignOrder(finalData));

        } else {
            console.log('Form has errors:', errors);
        }
    };

    const gotoItemApproval = (slug, inx) => {
        dispatch(setClearTradeState());
        dispatch(setCurrentItem(tradeDetails.order_items[inx]));
        navigate("/portal/trading/item-approval?type=order-item&view=" + slug);
    }
    const closeModal = () => {
        navigate("/portal/trading");
    }
    const disbursal = () => {
        if(tradeDetails.verified_phone_number !== 1){
            setVerifyPhoneNumber(true);
            return;
        }
        // dispatch(actionDisbursal(tradeDetails.slug));
        dispatch(setPaymentDisbursal(true));
    }
    const makePayment = () => {
        dispatch(setDisabledButton(true));
        dispatch(updateLoaderStatus(true));
        dispatch(actionDisbursal(tradeDetails.slug));
    }
    const closePaymentMsg = () => {
        dispatch(setPaymentDisbursalMsg(false));
        window.location.reload();
    }
    return (
        <>
            {defaultUserPermission && validatePermission(defaultUserPermission, PERMISSION_PAGE_KEY.TRADING, PERMISSION_KEY.READ) ? (

                <div className='container-fluid'>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className='row'>
                                <div className="col-md-12 mb-3">
                                    <div className='d-flex align-items-center'>
                                        <IconBack
                                            width={32}
                                            height={32}
                                            onIconClick={closeModal}
                                            customClass='cursor-pointer'
                                        />
                                        <h3 className='ms-2 flex-shrink-1 mb-0'>Trading ID: {tradeDetails && tradeDetails.row_ref_number}</h3>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <p className='m-2 fnt-size-14 '>Date: {tradeDetails && getDisplayDateTime(tradeDetails.created_at)}</p>
                                    <p className='m-2 fnt-size-14 '>Scheduled: {tradeDetails && getDisplayDateOnly(tradeDetails.scheduled_date) + ' ' + getDisplayTimeParaseOnly(tradeDetails.scheduled_time)}</p>
                                    <p className='m-2 fnt-size-14 '>Status: {tradeDetails && <GetOrderStatus status={tradeDetails.order_status} />}</p>
                                    {tradeDetails && tradeDetails.order_status === ORDER_STATUS.SETTLED.id && <p className='m-2 fnt-size-14 cursor-pointer'><a className='text-decoration-none text-muted' href={`${ENVIRONMENT.API_URL}service-trading/pdf/generate-pdf/${tradeDetails?.invoice_slug}`}>Download Receipt <IconDownload /></a></p>}
                                </div>


                                <div className="col-md-12 mt-3">
                                    <Tabs
                                        id="item-details-tab"
                                        activeKey={key}
                                        onSelect={(k) => setKey(k)}
                                        className=" mb-3"
                                    >

                                        <Tab eventKey="items" title="Products">
                                            <div className='row mb-3'>
                                                <div className='col-md-8 align-self-center'>
                                                    {tradeDetails && tradeDetails.payment_transactional && tradeDetails.payment_transactional.response_code != "0" && (<div className="from-group">
                                                        <p className='m-0'><strong>Payment status:</strong> <span className='badge badge-inactive'>{tradeDetails.payment_transactional.response_error_message}</span></p>
                                                    </div>)}
                                                    {tradeDetails && tradeDetails.payment_transactional && tradeDetails.payment_transactional.callback_response_code != "0" && (<div className="from-group">
                                                        <p className='m-0'><strong>Payment status:</strong> <span className='badge badge-inactive'>{tradeDetails.payment_transactional.result_desc}</span></p>
                                                    </div>)}
                                                </div>
                                                <div className='col-md-4 align-self-center'>
                                                    {defaultUserPermission && validatePermission(defaultUserPermission, PERMISSION_PAGE_KEY.TRADING, PERMISSION_KEY.DISBURSAL) && tradeDetails && tradeDetails.order_status === ORDER_STATUS.DISBURSAL.id && (
                                                        <div className='form-group text-end mt-1'>
                                                            <button
                                                                type='button'
                                                                className='btn btn-purple-primary'
                                                                onClick={disbursal}
                                                            >Disbursal</button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <form onSubmit={handleSubmit} noValidate>

                                                <div className='row'>
                                                    {tradeDetails && tradeDetails.order_status !== ORDER_STATUS.REJECTED.id && tradeDetails.order_status !== ORDER_STATUS.INPROGRESS.id && tradeDetails.order_status !== ORDER_STATUS.DISBURSAL.id && tradeDetails.order_status !== ORDER_STATUS.SETTLED.id ? (
                                                        <>

                                                            <div className="col-md-2">
                                                                <div className="form-group mb-3">
                                                                    <label>Pick-up Date</label>
                                                                    <Calendar
                                                                        value={reAssignFormData.scheduled_date ? new Date(reAssignFormData.scheduled_date) : ''}
                                                                        disabled={tradeDetails && tradeDetails.order_status === ORDER_STATUS.SETTLED.id ? true : false}
                                                                        onChange={handleChange}
                                                                        dateFormat="dd/mm/yy"
                                                                        name="scheduled_date"
                                                                        className='date-picker-input'
                                                                        minDate={new Date()}
                                                                        showIcon
                                                                    />

                                                                    {errors.scheduled_date && <span className="error-message">{errors.scheduled_date}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group mb-3">
                                                                    <label>Pick-up Time</label>
                                                                    <Calendar
                                                                        value={reAssignFormData.scheduled_time ? new Date(reAssignFormData.scheduled_time) : ''}
                                                                        disabled={tradeDetails && tradeDetails.order_status === ORDER_STATUS.SETTLED.id ? true : false}
                                                                        onChange={handleChange}
                                                                        name="scheduled_time"
                                                                        className='date-picker-input'
                                                                        showIcon timeOnly icon={() => <IconTime />}
                                                                        showTime
                                                                        hourFormat="12"
                                                                        maxDate={(() => {
                                                                            const endTime = new Date();
                                                                            endTime.setHours(21, 0, 0, 0); // Maximum time: 9:00 PM
                                                                            return endTime;
                                                                        })()}
                                                                    />

                                                                    {errors.scheduled_time && <span className="error-message">{errors.scheduled_time}</span>}
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="col-md-2">
                                                                <div className="form-group mb-3">
                                                                    <label>Pick-up Date</label>
                                                                    <Calendar
                                                                        value={reAssignFormData.scheduled_date ? new Date(reAssignFormData.scheduled_date) : ''}
                                                                        disabled={true}
                                                                        dateFormat="dd/mm/yy"
                                                                        name="scheduled_date"
                                                                        className='date-picker-input'
                                                                        minDate={new Date()}
                                                                        showIcon
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="form-group mb-3">
                                                                    <label>Pick-up Time</label>
                                                                    <Calendar
                                                                        value={reAssignFormData.scheduled_time ? new Date(reAssignFormData.scheduled_time) : ''}
                                                                        disabled={true}
                                                                        name="scheduled_time"
                                                                        className='date-picker-input'
                                                                        showIcon timeOnly icon={() => <IconTime />}
                                                                        showTime
                                                                        hourFormat="12"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}

                                                    {tradeDetails && tradeDetails.order_status !== ORDER_STATUS.REJECTED.id && tradeDetails.order_status !== ORDER_STATUS.INPROGRESS.id && tradeDetails.order_status !== ORDER_STATUS.DISBURSAL.id && tradeDetails.order_status !== ORDER_STATUS.SETTLED.id && (
                                                        <div className="col-md-2">
                                                            <div className="form-group mb-3">
                                                                <label className='w-100'>&nbsp;</label>
                                                                <button type='submit' className='btn btn-purple-primary'>Update</button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </form>
                                            {
                                                tradeDetails && (
                                                    <TradeItemTable
                                                        items={tradeDetails.order_items}
                                                        cc={tradeDetails.customer_contribution}
                                                        mc={tradeDetails.mc_contribution}
                                                        onRowSelected={gotoItemApproval}
                                                    />
                                                )
                                            }
                                        </Tab>

                                        <Tab eventKey="details" title="Customer & Agent">
                                            <div className='row trade-customer-section mt-5'>
                                                <h5><strong>Customer Information</strong></h5>

                                                <div className='col-md-4 col-12'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>Full Name</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && `${tradeDetails.customer_first_name} ${tradeDetails.customer_middle_name} ${tradeDetails.customer_last_name}`}</label>
                                                    </div>
                                                </div>
                                                <div className='col-md-4 col-12'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>National Identity Number</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && tradeDetails.customer_national_id}</label>
                                                    </div>
                                                </div>
                                                <div className='col-md-4 col-12'>
                                                    <div className='form-group'>
                                                        <label className='w-100'>Customer ID</label>
                                                        <label className='w-100 gray-color'>{tradeDetails && tradeDetails.customer_row_ref_number}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <form onSubmit={handleSubmit} noValidate>
                                                <div className='row trade-customer-section mt-5'>
                                                    <h5><strong>Asignee</strong></h5>


                                                    {tradeDetails && tradeDetails.order_status !== ORDER_STATUS.REJECTED.id && tradeDetails.order_status !== ORDER_STATUS.INPROGRESS.id && tradeDetails.order_status !== ORDER_STATUS.DISBURSAL.id && tradeDetails.order_status !== ORDER_STATUS.SETTLED.id ? (
                                                        <>
                                                            <div className="col-md-3">
                                                                <div className="form-group mb-3">
                                                                    <label>Organisation</label>
                                                                    {
                                                                        parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.ORGANIZATION ||
                                                                            parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.AGENT ? (
                                                                            <Dropdown
                                                                                disabled={true}
                                                                                value={reAssignFormData.organization_id}
                                                                                options={organizationList}
                                                                                optionLabel="organization_name"
                                                                                optionValue="id"
                                                                                placeholder="Select a organisation"
                                                                                className="w-100 custom-single-dropdown"
                                                                                name='organization_id'
                                                                            />
                                                                        ) : (
                                                                            <Dropdown
                                                                                value={reAssignFormData.organization_id}
                                                                                disabled={tradeDetails && tradeDetails.order_status === ORDER_STATUS.SETTLED.id ? true : false}
                                                                                onChange={handleChange}
                                                                                options={organizationList}
                                                                                optionLabel="organization_name"
                                                                                optionValue="id"
                                                                                placeholder="Select a organisation"
                                                                                className="w-100 custom-single-dropdown"
                                                                                name='organization_id'
                                                                            />
                                                                        )
                                                                    }
                                                                    {errors.organization_id && <span className="error-message">{errors.organization_id}</span>}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group mb-3">
                                                                    <label>Agent</label>
                                                                    {
                                                                        parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.AGENT ? (
                                                                            <Dropdown
                                                                                disabled={true}
                                                                                value={reAssignFormData.agent_id}
                                                                                options={agentList}
                                                                                optionLabel="full_name"
                                                                                optionValue="id"
                                                                                placeholder="Select a agent"
                                                                                className="w-100 custom-single-dropdown"
                                                                                name='agent_id'
                                                                            />
                                                                        ) : (
                                                                            <Dropdown
                                                                                value={reAssignFormData.agent_id}
                                                                                disabled={tradeDetails && tradeDetails.order_status === ORDER_STATUS.DISBURSAL.id ? true : false}
                                                                                onChange={handleChange}
                                                                                options={agentList}
                                                                                optionLabel="full_name"
                                                                                optionValue="id"
                                                                                placeholder="Select a agent"
                                                                                className="w-100 custom-single-dropdown"
                                                                                name='agent_id'
                                                                            />
                                                                        )
                                                                    }


                                                                    {errors.agent_id && <span className="error-message">{errors.agent_id}</span>}
                                                                </div>
                                                            </div>

                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="col-md-3">
                                                                <div className="form-group mb-3">
                                                                    <label>Organisation</label>
                                                                    <Dropdown
                                                                        disabled={true}
                                                                        value={reAssignFormData.organization_id}
                                                                        options={organizationList}
                                                                        optionLabel="organization_name"
                                                                        optionValue="id"
                                                                        placeholder="Select a organisation"
                                                                        className="w-100 custom-single-dropdown"
                                                                        name='organization_id'
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group mb-3">
                                                                    <label>Agent</label>
                                                                    <Dropdown
                                                                        disabled={true}
                                                                        value={reAssignFormData.agent_id}
                                                                        options={agentList}
                                                                        optionLabel="full_name"
                                                                        optionValue="id"
                                                                        placeholder="Select a agent"
                                                                        className="w-100 custom-single-dropdown"
                                                                        name='agent_id'
                                                                    />
                                                                </div>
                                                            </div>

                                                        </>
                                                    )}
                                                    {tradeDetails && tradeDetails.order_status !== ORDER_STATUS.REJECTED.id && tradeDetails.order_status !== ORDER_STATUS.INPROGRESS.id && tradeDetails.order_status !== ORDER_STATUS.DISBURSAL.id && tradeDetails.order_status !== ORDER_STATUS.SETTLED.id && [DEFAULT_USER_ROLE.SUPER_ADMIN, DEFAULT_USER_ROLE.ORGANIZATION, DEFAULT_USER_ROLE.ADMIN].includes(parseInt(userData.user_role_id)) && (
                                                        <div className="col-md-2">
                                                            <div className="form-group mb-3">
                                                                <label className='w-100'>&nbsp;</label>
                                                                <button type='submit' className='btn btn-purple-primary'>Update</button>
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>
                                            </form>

                                        </Tab>
                                        {tradeDetails && tradeDetails.payment_transactional && (
                                            <Tab eventKey="bankTransaction" title="Payment information">
                                                <div className='row'>


                                                    <div className='col-6'>
                                                        <h5>Invoice Details</h5>
                                                        {/* init payment error */}
                                                        {tradeDetails.payment_transactional.response_code !== "0" && (
                                                            <table className='table' cellPadding={3}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Status</td>
                                                                        <td><span className='badge badge-inactive'>Transaction failed</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Amount</td>
                                                                        <td>{tradeDetails && tradeDetails.payment_transactional && currencyFormatValue(tradeDetails.payment_transactional.amount)} KES</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Message</td>
                                                                        <td>{tradeDetails.payment_transactional.response_error_message}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Account ID</td>
                                                                        <td>{tradeDetails && tradeDetails.payment_transactional && tradeDetails.payment_transactional.party_a}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Reference ID</td>
                                                                        <td>{tradeDetails && tradeDetails.payment_transactional && tradeDetails.payment_transactional.originator_conversation_id}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Transaction Date and Time</td>
                                                                        <td>{tradeDetails && tradeDetails.payment_transactional && getDisplayDateTime(tradeDetails.payment_transactional.created_at)}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        )}
                                                        {/* {['0', '1', '3'].includes(tradeDetails.payment_transactional.callback_response_code) && ( */}
                                                            <table className='table' cellPadding={3}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Status</td>
                                                                        {tradeDetails.payment_transactional.callback_response_code === '0' && <td><span className='badge badge-active'>Transaction Completed</span></td>}
                                                                        {tradeDetails.payment_transactional.callback_response_code != 0 && <td><span className='badge badge-inactive'>{tradeDetails.payment_transactional.result_desc}</span></td>}
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Amount</td>
                                                                        <td>{tradeDetails && tradeDetails.payment_transactional && currencyFormatValue(tradeDetails.payment_transactional.amount)} KES</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Transaction ID</td>
                                                                        <td>{tradeDetails && tradeDetails.payment_transactional && tradeDetails.payment_transactional.transaction_id}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Account ID</td>
                                                                        <td>{tradeDetails && tradeDetails.payment_transactional && tradeDetails.payment_transactional.party_a}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Reference ID</td>
                                                                        <td>{tradeDetails && tradeDetails.payment_transactional && tradeDetails.payment_transactional.originator_conversation_id}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Transaction Date and Time</td>
                                                                        <td>{tradeDetails && tradeDetails.payment_transactional && getDisplayDateTime(tradeDetails.payment_transactional.created_at)}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        {/* )} */}
                                                    </div>
                                                </div>
                                            </Tab>
                                        )}
                                    </Tabs>
                                </div>
                            </div>
                            <Dialog
                                header=""
                                // position='top'
                                visible={paymentDisbursalMsg}
                                style={{ width: isMobileScreen ? '100vw' : '25vw' }}
                                onHide={closePaymentMsg}
                            >
                                <div className='form-group'>
                                    <IconErrorWarning width={20} height={20} /> We have initiated the transaction with M-Pesa, Please wait for few minutes until the transaction is completed. You can close this popup and continue with the other tasks and check again after few minutes
                                </div>
                                <div className='form-group mt-5 text-end mb-3'>
                                    <button
                                        className="btn  btn-purple-primary"
                                        type='button'
                                        onClick={closePaymentMsg}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog>
                            <Dialog
                                header="Verify the Phone number"
                                visible={verifyPhoneNumber}
                                style={{ width: isMobileScreen ? '100vw' : '50vw' }}
                                onHide={() => {
                                    setVerifyPhoneNumber(false);
                                }}
                                maximizable
                                className='approved-item-list'
                            >
                                <div className='form-group mb-3 text-center'>
                                    <p>Customer phone number not verified please verfiy the phone number</p>
                                </div>
                                <div className='form-group mb-3 text-center'>
                                    <button
                                        className="btn  btn-purple-primary"
                                        type='button'
                                        onClick={e => {
                                            setVerifyPhoneNumber(false);
                                            dispatch(actionSendOtp(tradeDetails && tradeDetails.customer_phone_number));
                                        }}
                                    >Verify the phone number
                                    </button>
                                </div>
                            </Dialog>
                            <Dialog
                                header="Approved Order Items"
                                // position='top'
                                visible={paymentDisbursal}
                                style={{ width: isMobileScreen ? '100vw' : '75vw' }}
                                onHide={() => {
                                    dispatch(setPaymentDisbursal(false));
                                }}
                                maximizable
                                className='approved-item-list'
                            >
                                {tradeDetails && <ItemDetails cc={tradeDetails.customer_contribution} mc={tradeDetails.mc_contribution} items={tradeDetails.order_items} onlyApproved={true} />}

                                <div className='form-group mt-5 text-end mb-3'>
                                    <button
                                        className="btn  btn-purple-primary-outline me-3"
                                        type='button'
                                        onClick={(e) => {
                                            dispatch(setPaymentDisbursal(false));
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        disabled={disabledButton}
                                        className="btn  btn-purple-primary"
                                        type='button'
                                        onClick={makePayment}
                                    >
                                        {disabledButton ? <Spinner /> : null} Proceed to payment
                                    </button>
                                </div>
                            </Dialog>
                        </div>
                    </div>
                </div>
            ) : defaultUserPermission && <PermissionDenied />}
            { tradeDetails && <VerifyOtp userId={tradeDetails.customer_id} /> }
        </>
    );
}


export default TradingDetails;

import React, { useState } from 'react';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'
import {  forgotPassword } from '../../../store/auth/actions';
import { setDefaultFormData, setFormData, setShowForgotPassword, setShowForgotPasswordMsg, setShowLogin } from '../../../store/auth/reducer';
import { setDisabledButton, setLoginSection } from '../../../store/general/reducer';
import { REGEXP } from '../../../config/constant';
import { IconLongBack, Spinner } from '../../../svg-icons';

const ForgotPasswordPage = () => {
  

    const { formData } = useSelector(state => state.auth);
    const { disabledButton } = useSelector((state) => state.general);
  
    const dispatch = useDispatch();
  
    const [errors, setErrors] = useState({});
  
    const schema = Yup.object().shape({
      email: Yup.string()
        .matches(REGEXP.emailRegExp, 'Invalid email address')
        .required('Email is required')
    });
  
    const validateField = async (name, value) => {
      try {
        await Yup.reach(schema, name).validate(value);
        setErrors({ ...errors, [name]: '' });
      } catch (validationError) {
        setErrors({ ...errors, [name]: validationError.message });
      }
    };
    const validateForm = async () => {
      try {
        await schema.validate(formData, { abortEarly: false });
        setErrors({});
        return true;
      } catch (validationErrors) {
        const newErrors = validationErrors.inner.reduce((acc, err) => {
          acc[err.path] = err.message;
          return acc;
        }, {});
        setErrors(newErrors);
        return false;
      }
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      const isValid = await validateForm();
  
      if (isValid) {
        dispatch(setDisabledButton(true));
        dispatch(forgotPassword({email: formData.email}));       
      } else {
        console.log('Form has errors:', errors);
      }
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      let data = {
        ...formData,
        [name]: value,
      };
      dispatch(setFormData(data));
      validateField(name, value);
    };
  
  
    // go to the forgot password section
    const showLoginSection = async () => {
      dispatch(setShowLogin(true));
      dispatch(setShowForgotPassword(false));
      dispatch(setShowForgotPasswordMsg(false));
      dispatch(setDefaultFormData());
    }
  
    return (  
      <div className="row">
        <form onSubmit={handleSubmit} noValidate>
          <div className="row">
            <div className="col-12 text-center mb-5">
              <h3 className='text-center'>
                <IconLongBack
                width={46}
                height={32}
                customClass='cursor-pointer'
                onIconClick={showLoginSection}
                />
                Forgot Password
              </h3>
              <p className='m-0 text-center'>Enter your email address that you used on Madini Chapaa.</p>
              <p className='text-center'>We will send you a password reset email.</p>
            </div>
            <div className='login-section-pd'>
              <div className="col-12">
                <div className="form-group mb-3">
                  <label>Email address</label>
                  <input type="email" 
                  className="form-control"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder='Enter the email address'
                  />
                  {errors.email && <span className="error-message">{errors.email}</span>}
                </div>
  
                <div className="form-group text-center">  
                  <button
                    disabled={disabledButton}
                    type="submit"
                    className="btn-block btn btn-orange-primary"
                  >
                    {disabledButton ? <Spinner /> : null}  Send
                  </button>  
                </div>
              </div>
            </div>
          </div>
        </form >
      </div>
    );
  }
  export default ForgotPasswordPage;
// Import files and libraries
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';

import { Calendar } from 'primereact/calendar';

import * as Yup from 'yup';
import { Dropdown as ButtonDropdown } from 'react-bootstrap';

//others
import { IconBack, IconCurrentStep, IconDelete, IconPlus, IconTime, Spinner } from '../../../svg-icons';

// reducer
import { setFormModel, setFormData, setRemoveItem, setAddProductItems, setItemUpdate, setSelectedCustomer, setItemFileDocumentPlaceholder } from '../../../store/trade/reducer';
import { setClearGeneralState, setDisabledButton, setShowCamera, updateLoaderStatus } from '../../../store/general/reducer';

// action
import { actionCreate, actionGetSubCatAttrWithInx, actionMultipleUpload, actionSearchCustomer, actionSingleUpload } from '../../../store/trade/action'
import { DEFAULT_USER_ROLE, ENVIRONMENT, TOAST_TYPE } from '../../../config/constant';
import { getDatabaseDateOnly, getDatabaseDateTime, getDatabaseTimeOnly, getDisplayDateOnly, getDisplayTimeOnly } from '../../../helper/date-helper';
import { showToast } from '../../../helper/toast-service-helper';
import { base64ToFile, checkFileUpload, currencyFormatValue, fileAccept, fixedTwoDigit, formatFileSize, getFileExtension, profileAccept, truncateFileName } from '../../../helper/common-helper';

import { actionGetSubCategoryList } from '../../../store/sub-category/action';
import { actionGetAgentList } from '../../../store/agent/action';
import ItemDetails from '../../../component/item-details';
import MultipleFilePreview from '../../../component/multiple-file-upload';
import FilePreview from '../../../component/file-preview';
import { actionClearData } from '../../../store/general/action';
import CameraUpload from '../../../component/camera-upload';
import SingleFilePreview from '../../../component/single-file-upload';
import VerifyOtp from '../../../component/verify-otp';
import { setShowVerifyOtp } from '../../../store/auth/reducer';
import { actionSendOtp } from '../../../store/auth/actions';


const TradeingFormModel = () => {
    // create a dispatch
    const dispatch = useDispatch();

    // create a state
    const [errors, setErrors] = useState(false);
    const [cameraUploadFile, setCameraUploadFile] = useState("");
    const [cameraUploadFileInx, setCameraUploadFileInx] = useState(null);

    const fileSD = useRef([]);
    const fileMetalPhoto = useRef([]);

    const triggerFileMetalInput = (index) => {

        if (fileMetalPhoto.current[index]) {
            fileMetalPhoto.current[index].click();
        }
    };
    const triggerFileSDInput = (index) => {

        if (fileSD.current[index]) {
            fileSD.current[index].click();
        }
    };

    const [searchCustomer, setSearchCustomer] = useState("");
    const [currentStep, setCurrentStep] = useState(1);

    const {
        formData,
        formModel,
        selectedCustomer,
        productItems, customerProfile
    } = useSelector((state) => state.trade);

    const { disabledButton } = useSelector((state) => state.general);
    const { subCategoryList } = useSelector((state) => state.subCategory);
    const { organizationList } = useSelector((state) => state.organization);
    const { agentList } = useSelector((state) => state.agent);
    const { userData, showVerifyOtp } = useSelector((state) => state.auth);
    const { validatedPhoneNumber } = useSelector((state) => state.customer);

    // form validation schema
    let schema = Yup.object().shape({

        organization_id: Yup.string()
            .required('Organization is required'),
        agent_id: Yup.string()
            .required('Agent is required'),
        scheduled_date: Yup.string()
            .required('Schedule Date is required'),
        scheduled_time: Yup.string()
            .required('Schedule Time is required'),

    });


    //validate the fields
    const validateField = async (name, value) => {
        try {
            await Yup.reach(schema, name).validate(value);
            setErrors({ ...errors, [name]: '' });
        } catch (validationError) {
            setErrors({ ...errors, [name]: validationError.message });
        }
    };

    //validate the form
    const validateForm = async () => {
        try {
            await schema.validate(formData, { abortEarly: false });
            setErrors({});
            return true;
        } catch (validationErrors) {
            const newErrors = validationErrors.inner.reduce((acc, err) => {
                acc[err.path] = err.message;
                return acc;
            }, {});
            setErrors(newErrors);
            return false;
        }
    };

    const handleSearch = (event) => {
        const searchQuery = event.target.value;
        setSearchCustomer(searchQuery);

    }
    const gotoNextStep = async () => {
        if (currentStep === 2) {
            let hasErrors = false; // Local variable to track errors

            productItems.map((row, inx) => {
                if (row.product_id === '') {
                    showToast(TOAST_TYPE.ERROR, `#${inx + 1} Please select the product`, 'top-right');
                    hasErrors = true;
                }
                if (row.purity === 0) {
                    showToast(TOAST_TYPE.ERROR, `#${inx + 1} Please select the purity`, 'top-right');
                    hasErrors = true;
                }
                if (row.weight <= 0) {
                    showToast(TOAST_TYPE.ERROR, `#${inx + 1} Please provide the weight`, 'top-right');
                    hasErrors = true;
                }
            });

            // After the loop, update the state and take actions accordingly
            if (!hasErrors) {
                // No errors, move to the next step
                setCurrentStep(currentStep + 1);
            } else {
                // Set errors in state if needed
                setErrors(true);
            }
        } else if (currentStep === 4) {
            // get the form status
            if (errors.scheduled_time) {
                // If there's an error, do not move to the next step
                showToast(TOAST_TYPE.ERROR, errors.scheduled_time, 'top-right');
                return; // Prevent going to the next step
            }

            // Get the form status (check if the form is valid)
            const isValid = await validateForm();

            if (isValid) {
                setCurrentStep(currentStep + 1); // Move to the next step
            }
        }
    }

    const deleteItem = (inx) => {
        let newItems = productItems.filter((item, itemInx) => inx != itemInx);
        dispatch(setRemoveItem([...newItems]));
    }

    const addItem = () => {
        dispatch(setAddProductItems());
    }

    const updateViewCustomer = () => {
        dispatch(setSelectedCustomer(null));
        dispatch(actionSearchCustomer(searchCustomer));
    }

    useEffect(() => {
        if (validatedPhoneNumber) {
            createApplication();
        }
    }, [validatedPhoneNumber]);
    
    useEffect(() => {
        setErrors(false);
        dispatch(actionGetSubCategoryList(1));
        setDefaultOrg();
        dispatch(setClearGeneralState());
        let data = {
            ...formData,
            scheduled_date: new Date(),
            scheduled_time: new Date()
        }
        dispatch(setFormData(data))
    }, [formModel]);

    const setDefaultOrg = () => {
        if (parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.ORGANIZATION) {
            let data = {
                ...formData,
                organization_id: userData.id,
            };

            dispatch(setFormData(data));
            dispatch(actionGetAgentList(userData.id));
        } else if (parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.AGENT) {
            let data = {
                ...formData,
                organization_id: userData.organization_id,
                agent_id: userData.id
            };

            dispatch(setFormData(data));
            dispatch(actionGetAgentList(userData.organization_id));
        }
    }

    // form modal close
    const closeModal = async () => {
        dispatch(setFormModel(false));
    };


    // handling the input changes
    const handleChange = async (e) => {
        let { name, value } = e.target;

        // Check if it's a regular input or a Calendar component
        if (e.target) {
            ({ name, value } = e.target);
        } else {
            name = e.target.name;
            value = e.value; // Calendar component passes the value as Date object
        }

        // If the name is 'scheduled_time' (pick-up time)
        if (name === 'scheduled_time') {
            const selectedTime = new Date(value);
            const currentTime = new Date();
            const selectedDate = new Date(formData.scheduled_date); // Getting the selected date

            // Set the allowed time range for future dates: 9:00 AM to 9:00 PM
            const startTime = new Date();
            startTime.setHours(9, 0, 0, 0); // 9:00 AM today

            const endTime = new Date();
            endTime.setHours(21, 0, 0, 0); // 9:00 PM today

            const minAllowedTime = new Date(currentTime);
            minAllowedTime.setMinutes(currentTime.getMinutes() + 30);

            // Check if the selected date is today (same date, ignoring time)
            if (selectedDate.toDateString() === currentTime.toDateString()) {
                // For today, set the time to current time + 30 minutes
                const timeWith30Minutes = new Date(currentTime);
                timeWith30Minutes.setMinutes(currentTime.getMinutes() + 30);

                // If the selected time is before 30 minutes from current time, adjust it
                if (selectedTime < minAllowedTime) {
                    value = timeWith30Minutes; // Set time to current time + 30 minutes
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        scheduled_time: "Pick-up time for today must be at least 30 minutes from now."
                    }));
                } else {
                    // Clear the error if the selected time is valid
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        scheduled_time: ""
                    }));
                }
            } else {
                // If the selected date is in the future, ensure the time is within 9:00 AM to 9:00 PM
                if (selectedTime < startTime) {
                    value = startTime; // Set time to 9:00 AM if it's before
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        scheduled_time: "Pick-up time must be between 9:00 AM and 9:00 PM."
                    }));
                } else if (selectedTime >= endTime) {
                    value = endTime; // Set time to 9:00 PM if it's after
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        scheduled_time: "Trading hours are over for today; please adjust the pickup date and time."
                    }));
                } else {
                    // Clear the error if the time is within range
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        scheduled_time: ""
                    }));
                }
            }
        }

        // Update form data with the new value
        const data = {
            ...formData,
            [name]: value,
        };

        // Dispatch updated data to state
        if (name === 'organization_id') {
            dispatch(actionGetAgentList(value));
        }

        dispatch(setFormData(data));

        // Validate the field (optional, depending on your validation system)
        validateField(name, value);
    };



    const handleItemUpdate = async (inx, e) => {
        const { name, value } = e.target;


        if (name === 'product_id') {
            const productDetails = subCategoryList.filter(subCat => value === subCat.id)[0];
            const metalItem = {
                ...productItems[inx],
                [name]: value,
                loyalty: productDetails.loyalty_percentage,
                per_gram_price: productDetails.per_gram_price,
                certification_need: productDetails.certification_need,
                certification_charges: productDetails.certification_charges,
                product_name: productDetails.sub_category_name
            };
            dispatch(setItemUpdate({ inx, metalItem }));

        } else {
            const metalItem = { ...productItems[inx], [name]: value };
            dispatch(setItemUpdate({ inx, metalItem }));

        }

        if (name === 'product_id') {
            dispatch(actionGetSubCatAttrWithInx(value, inx));
        }
    }

    const fileHandling = (e, itemInx) => {

        const checkFile = e.target.files[0]; // Get the selected file
        if (!checkFile) return;
        const name = e.target.name;

        if (name === 'customer_photo' || name === 'metal_photo' || name === 'weight_photo' || name === 'purity_photo') {
            const ckf = checkFileUpload(checkFile);
            if (ckf) {
                let ext = getFileExtension(checkFile.name);
                dispatch(setItemFileDocumentPlaceholder({
                    name,
                    itemInx,
                    file: {
                        name: truncateFileName(checkFile.name),
                        type: checkFile.type,
                        ext,
                        size: formatFileSize(checkFile.size),
                        isNew: true
                    }
                }));

                const formSDData = new FormData();
                formSDData.append('file', checkFile); // Append the file to the FormData object

                dispatch(actionMultipleUpload(formSDData, itemInx, name));
            }
        }
        else if (name === 'supporting_documents') {
            Array.from(e.target.files).forEach((file) => {
                const ckf = checkFileUpload(file);
                if (ckf) {
                    let inx = 'file_' + Math.floor(Math.random() * (999 - 100 + 1)) + 100;
                    let ext = getFileExtension(file.name);
                    dispatch(setItemFileDocumentPlaceholder({
                        name,
                        itemInx,
                        file: {
                            name: truncateFileName(file.name),
                            type: file.type,
                            ext,
                            size: formatFileSize(file.size),
                            isNew: true
                        }, inx
                    }));
                    const formSDData = new FormData();
                    formSDData.append('file', file); // Append the file to the FormData object

                    dispatch(actionMultipleUpload(formSDData, itemInx, name, inx));
                }
            });
        }
    }



    const createApplication = () => {
        if (!showVerifyOtp && !validatedPhoneNumber) {
            dispatch(actionSendOtp(selectedCustomer.phone_number, 'trade'));
            return;
        }
        if (!validatedPhoneNumber) {
            showToast(
                TOAST_TYPE.ERROR,
                `Please verify the phone number before sumbmit the trade`,
                "top-right"
            );
            return;
        }
        let order_items = [];
        productItems.forEach(item => {

            const customer_photo = item.customer_photo !== null ? item.customer_photo.file.id : null;
            const metal_photo = item.metal_photo !== null ? item.metal_photo.id : null;
            const weight_photo = item.weight_photo !== null ? item.weight_photo.file.id : null;
            const purity_photo = item.purity_photo !== null ? item.purity_photo.file.id : null;

            let supporting_documents = [];
            if (Object.values(item.supporting_documents).length > 0) {
                Object.values(item.supporting_documents).map(doc => {
                    if (doc?.isNew) {
                        supporting_documents.push(doc.id);
                    }
                });
            }

            order_items.push({
                loyalty: item.loyalty,
                per_gram_price: item.per_gram_price,
                certification_charges: item.certification_need === 1 ? fixedTwoDigit(item.certification_charges) : 0,
                price: fixedTwoDigit(item.price),
                purity: item.purity,
                weight: fixedTwoDigit(item.weight),
                product_id: item.product_id,
                customer_photo,
                metal_photo,
                weight_photo,
                purity_photo,
                supporting_documents
            });
        });
        let data = {
            customer_contribution: fixedTwoDigit(formData.customer_contribution),
            mc_contribution: fixedTwoDigit(formData.mc_contribution),
            customer_id: selectedCustomer.id,
            agent_id: formData.agent_id,
            location_id: selectedCustomer.location_id,
            organization_id: formData.organization_id,
            scheduled_date: getDatabaseDateOnly(formData.scheduled_date),
            scheduled_time: getDatabaseTimeOnly(formData.scheduled_time),
            order_items
        };
        dispatch(setDisabledButton(true));
        dispatch(updateLoaderStatus(true));
        dispatch(actionCreate(data));
    }

    const handleCapture = (e) => {

        const file = base64ToFile(e);
        if (!file) return;

        const name = cameraUploadFile;
        const itemInx = cameraUploadFileInx;

        let inx = 'file_' + Math.floor(Math.random() * (999 - 100 + 1)) + 100;
        let ext = 'png';
        dispatch(setItemFileDocumentPlaceholder({
            name,
            itemInx,
            file: {
                name: truncateFileName(file.name),
                type: file.type,
                ext,
                size: formatFileSize(file.size),
                isNew: true
            }, inx
        }));
        const formSDData = new FormData();
        formSDData.append('file', file); // Append the file to the FormData object

        dispatch(actionMultipleUpload(formSDData, itemInx, name, inx));

    }
    const formatAddress = (address, length) => {
        let formattedAddress = '';
        for (let i = 0; i < address.length; i += length) {
            formattedAddress += address.substring(i, i + length) + '\n';
        }
        return formattedAddress;
    };

    return (
        <>
            <div className='row'>
                <div className="col-md-10 mb-3">
                    <div className='d-flex align-items-center'>
                        <IconBack
                            width={42}
                            height={42}
                            onIconClick={closeModal}
                            customClass='cursor-pointer'
                        />
                        <h3 className='ms-2 flex-shrink-1 mb-0'> Create New Trading</h3>
                    </div>
                </div>

            </div>
            <div className='row'>
                <div className="col-12 mt-3">
                    <div className='bg-stepper-line position-relative'>
                        <div className='row stepper'>
                            <div className='col-md-2 col-md-auto mb-2'>
                                <p className='m-0 text-nowrap '>
                                    {currentStep > 1 ? (
                                        <IconCurrentStep
                                            width={40}
                                            height={40}
                                            bgColor='#10B981'
                                            customClass='top-2'
                                        />
                                    ) : (<IconCurrentStep
                                        width={40}
                                        height={40}
                                        customClass='top-2'
                                    />)}

                                    <span className='ms-1'>Select Customer</span>
                                </p>
                            </div>
                            <div className='col-md-2 col-md-auto mb-2'>

                                <p className='m-0 text-nowrap '>
                                    {currentStep < 2 && (
                                        <>
                                            <span className='default-step me-1 float-start'>02</span>
                                            <span className='ms-1 lh-40'>Item Details</span>
                                        </>
                                    )}
                                    {currentStep === 2 && (
                                        <>
                                            <IconCurrentStep
                                                width={40}
                                                height={40}
                                                customClass='top-2'
                                            />
                                            <span className='ms-1 '>Item Details</span>
                                        </>
                                    )}
                                    {currentStep > 2 && (
                                        <>
                                            <IconCurrentStep
                                                width={40}
                                                height={40}
                                                bgColor='#10B981'
                                                customClass='top-2'
                                            />
                                            <span className='ms-1'>Item Details</span>
                                        </>
                                    )}
                                </p>
                            </div>

                            <div className='col-md-2 col-md-auto mb-2'>
                                <p className='m-0 text-nowrap '>
                                    {currentStep < 3 && (
                                        <>
                                            <span className='default-step me-1 float-start'>03</span>
                                            <span className='ms-1 lh-40'>Wallet</span>
                                        </>
                                    )}
                                    {currentStep === 3 && (
                                        <>
                                            <IconCurrentStep
                                                width={40}
                                                height={40}
                                                customClass='top-2'
                                            />
                                            <span className='ms-1'>Wallet</span>
                                        </>
                                    )}
                                    {currentStep > 3 && (
                                        <>
                                            <IconCurrentStep
                                                width={40}
                                                height={40}
                                                bgColor='#10B981'
                                                customClass='top-2'
                                            />
                                            <span className='ms-1'>Wallet</span>
                                        </>
                                    )}
                                </p>
                            </div>
                            <div className='col-md-2 col-md-auto mb-2'>
                                <p className='m-0 text-nowrap '>
                                    {currentStep < 4 && (
                                        <>
                                            <span className='default-step me-1 float-start'>04</span>
                                            <span className='ms-1 lh-40'>Schedule</span>
                                        </>
                                    )}
                                    {currentStep === 4 && (
                                        <>
                                            <IconCurrentStep
                                                width={40}
                                                height={40}
                                                customClass='top-2'
                                            />
                                            <span className='ms-1'>Schedule</span>
                                        </>
                                    )}
                                    {currentStep > 4 && (
                                        <>
                                            <IconCurrentStep
                                                width={40}
                                                height={40}
                                                bgColor='#10B981'
                                                customClass='top-2'
                                            />
                                            <span className='ms-1'>Schedule</span>
                                        </>
                                    )}
                                </p>
                            </div>
                            <div className='col-md-2 col-md-auto mb-2'>
                                <p className='m-0 text-nowrap '>

                                    {currentStep < 5 && (
                                        <>
                                            <span className='default-step me-1 float-start'>05</span>
                                            <span className='ms-1 lh-40'>Verification</span>
                                        </>

                                    )}
                                    {currentStep === 5 && (
                                        <>
                                            <IconCurrentStep
                                                width={40}
                                                height={40}
                                                customClass='top-2'
                                            />
                                            <span className='ms-1'>Verification</span>
                                        </>
                                    )}
                                    {currentStep > 5 && (
                                        <>
                                            <IconCurrentStep
                                                width={40}
                                                height={40}
                                                bgColor='#10B981'
                                                customClass='top-2'
                                            />
                                            <span className='ms-1'>Verification</span>
                                        </>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

                {/* step content start */}
                {/* STEP 1 START */}
                {currentStep === 1 && (
                    <>
                        <div className='col-12 mt-5'>
                            <div className='row'>
                                <div className='col-md-3 col-12 mb-2'>
                                    <input type="text" className="form-control "
                                        value={searchCustomer}
                                        onChange={handleSearch}
                                        placeholder="Enter customer phone number or national ID"
                                    />
                                </div>
                                <div className='col-md-3 col-12 '>
                                    <button
                                        className="btn  btn-purple-primary"
                                        type='button'
                                        onClick={updateViewCustomer}
                                    >
                                        View Customer
                                    </button>
                                </div>
                            </div>
                        </div>
                        {selectedCustomer === null && (
                            <p className='m-0 mt-5 text-center'>Search customer phone number or national ID</p>
                        )}
                        {selectedCustomer === false && (
                            <p className='m-0 mt-5 text-center'>No records, Search customer phone number or national ID</p>
                        )}
                        <div className='col-12 mt-5 trade-customer-section'>
                            {selectedCustomer && (<>
                                <div className='row'>
                                    <div className='col-md-4 col-12'>
                                        <div className='row'>
                                            <div className='col-3 align-self-center'>
                                                {customerProfile ? (
                                                    <div className='placeholder-image-bg'>
                                                        <img src={`data:${customerProfile?.type};base64,${customerProfile?.base64}`} />
                                                    </div>
                                                ) : (
                                                    <div className='placeholder-image-bg'>
                                                        <img src={`${ENVIRONMENT.BASE_URL}assets/images/profile.png`} />
                                                    </div>
                                                )}
                                            </div>
                                            <div className='col-md-9 col-12 align-self-center'>
                                                <p className='m-0'><strong>Customer ID: {selectedCustomer.row_ref_number}</strong></p>
                                                <p className='m-0 gray-color'>{selectedCustomer.first_name} {selectedCustomer.middle_name} {selectedCustomer.last_name}</p>
                                                <p className='m-0 gray-color'>{selectedCustomer.email}</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-12'>
                                        <div className='form-group'>
                                            <label className='w-100'>Location</label>
                                            <label className='w-100 gray-color'>{selectedCustomer.location_name}</label>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-12'>
                                        <div className='form-group'>
                                            <label className='w-100'>Agent</label>
                                            <label className='w-100 gray-color'>{`${selectedCustomer.agent_first_name} ${selectedCustomer.agent_middle_name} ${selectedCustomer.agent_last_name}`}</label>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-12'>
                                        <div className='form-group'>
                                            <label className='w-100'>Organisation</label>
                                            <label className='w-100 gray-color'>{selectedCustomer.organization_name}</label>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-12'>
                                        <div className='form-group'>
                                            <label className='w-100'>National Identity Number</label>
                                            <label className='w-100 gray-color'>{selectedCustomer.national_id}</label>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-12'>
                                        <div className='form-group'>
                                            <label className='w-100'>Serial Number</label>
                                            <label className='w-100 gray-color'>{selectedCustomer.serial_number}</label>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-12'>
                                        <div className='form-group'>
                                            <label className='w-100'>Phone Number</label>
                                            <label className='w-100 gray-color'>{selectedCustomer.phone_number}</label>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-12'>
                                        <div className='form-group'>
                                            <label className='w-100'>Gender</label>
                                            <label className='w-100 gray-color'>{selectedCustomer.gender}</label>
                                        </div>
                                    </div>

                                    <div className='col-md-4 col-12'>
                                        <div className='form-group'>
                                            <label className='w-100'>Citizenship</label>
                                            <label className='w-100 gray-color'>{selectedCustomer.citizen_ship}</label>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-12'>
                                        <div className='form-group'>
                                            <label className='w-100'>Date of Birth</label>
                                            <label className='w-100 gray-color'>{getDisplayDateOnly(selectedCustomer.dob)}</label>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-12'>
                                        <div className='form-group'>
                                            <label className='w-100'>Marital Status</label>
                                            <label className='w-100 gray-color'>{selectedCustomer.marital_status}</label>
                                        </div>
                                    </div>

                                    <div className='col-md-4 col-12'>
                                        <div className='form-group'>
                                            <label className='w-100'>Next of kin Name</label>
                                            <label className='w-100 gray-color'>{selectedCustomer.nominee_name}</label>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-12'>
                                        <div className='form-group'>
                                            <label className='w-100'>Next of kin Relationship</label>
                                            <label className='w-100 gray-color'>{selectedCustomer.nominee_relationship}</label>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-12'>
                                        <div className='form-group'>
                                            <label className='w-100'>Next of kin Phone Number</label>
                                            <label className='w-100 gray-color'>{selectedCustomer.nominee_phone_number}</label>
                                        </div>
                                    </div>
                                    {/* <div className='col-md-4 col-12'>
                                        <div className='form-group'>
                                            <label className='w-100'>Remarks</label>
                                            <label className='w-100 gray-color'>{selectedCustomer.remarks}</label>
                                        </div>
                                    </div> */}
                                    <div className='col-md-4 col-12'>
                                        <div className='form-group'>
                                            <label className='w-100'>Occupation</label>
                                            <label className='w-100 gray-color'>{selectedCustomer.occupation}</label>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-12'>
                                        <div className='form-group'>
                                            <label className='w-100'>Address</label>
                                            <label className='w-100 gray-color'>
                                                {formatAddress(selectedCustomer.address, 50).split('\n').map((line, index) => (
                                                    <React.Fragment key={index}>
                                                        {line}
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </label>
                                        </div>
                                    </div>

                                    <div className='clear-fix mb-5'></div>
                                    <div className="col-12 mt-3 text-end">
                                        <div className="form-group">
                                            <button
                                                className="btn  btn-purple-primary"
                                                type='button'
                                                onClick={e => setCurrentStep(currentStep + 1)}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>)}
                        </div>
                    </>
                )}
                {/* STEP 1 END */}

                {/* STEP 2 START */}
                {currentStep === 2 && (
                    <div className='col-12 mt-5'>
                        {productItems.length > 0 && productItems.map((item, index) => (
                            <div key={index + '-item'}>
                                <div className='item-box mb-3'>
                                    <div className='item-box-list position-relative'>
                                        <button
                                            className='cursor-pointer position-absolute top-0 end-0 delete-item-icon btn'
                                            onClick={e => deleteItem(index)}

                                        >
                                            <IconDelete
                                                customClass='delete-item-icon'
                                            />
                                        </button>
                                        <div className='form-group'>
                                            <label>Enter Metal Details</label>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-1 col-12 align-self-center'>#{index + 1}</div>
                                            <div className='col-md-2 col-12 mb-2'>
                                                <div className='form-group'>
                                                    <label>Metal Type</label>
                                                    <Dropdown
                                                        value={item.product_id}
                                                        options={subCategoryList}
                                                        optionLabel="sub_category_name"
                                                        optionValue="id"
                                                        placeholder="Select a metal"
                                                        className="w-100 custom-single-dropdown"
                                                        name='product_id'
                                                        onChange={(e) => handleItemUpdate(index, e)}
                                                    // filter
                                                    />
                                                </div>
                                            </div>

                                            <div className='col-md-2 col-12 mb-2'>
                                                <div className='form-group'>
                                                    <label>Weight</label>
                                                    <div className="input-group mb-3">
                                                        <input type="number"
                                                            className="form-control "
                                                            name="weight"
                                                            value={item.weight}
                                                            onChange={(e) => handleItemUpdate(index, e)}
                                                        />
                                                        <span className="input-group-text" id="basic-addon2">Gm</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-3 col-12 mb-2'>
                                                <div className='form-group'>
                                                    <label>Purity</label>
                                                    <div className="remove-dd input-group mb-3">
                                                        <Dropdown
                                                            value={item.purity}
                                                            options={item.product_attributes}
                                                            optionLabel="attribute_value"
                                                            optionValue="attribute_value"
                                                            placeholder="Select a purity"
                                                            className="w-50 me-0 custom-single-dropdown"
                                                            name='purity'
                                                            onChange={(e) => handleItemUpdate(index, e)}
                                                        // filter
                                                        />
                                                        <span className="input-group-text" id="basic-addon2">Carat</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-3 col-12 mb-2'>
                                                <div className='form-group'>
                                                    <label> Price (KES)</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" className="form-control "
                                                            // name="price"
                                                            disabled={true}
                                                            value={currencyFormatValue(fixedTwoDigit(fixedTwoDigit(item.price) * item.weight))}
                                                        // onChange={handleChange}
                                                        />
                                                        <span className="input-group-text" id="basic-addon2">Price</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <label>Upload Documents</label>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-3 col-12 mb-2'>
                                                <label className='w-100'>Metal Photo</label>
                                                <ButtonDropdown className='mb-2'>
                                                    <ButtonDropdown.Toggle className='btn btn-orange-primary-outline text-orange'>
                                                        Upload
                                                    </ButtonDropdown.Toggle>
                                                    <ButtonDropdown.Menu>
                                                        <ButtonDropdown.Item onClick={e => {
                                                            setCameraUploadFile('metal_photo');
                                                            setCameraUploadFileInx(index);
                                                            dispatch(setShowCamera(true));
                                                        }}>Capture from camera</ButtonDropdown.Item>
                                                        <ButtonDropdown.Item onClick={e => triggerFileMetalInput(index)}>

                                                            Upload from your computer</ButtonDropdown.Item>
                                                    </ButtonDropdown.Menu>
                                                </ButtonDropdown>
                                                <input accept={profileAccept} ref={el => fileMetalPhoto.current[index] = el} onChange={e => fileHandling(e, index)} type='file' name="metal_photo" className='d-none' />
                                                {item.metal_photo && <SingleFilePreview file={item.metal_photo} />}
                                            </div>
                                            {/* <div className='col-3 mb-2'>
                                                <label className='w-100'>Customer Photo</label>
                                                <input onChange={e => fileHandling(e, index)} type='file' name='customer_photo' className='form-control cus-file-upload' />
                                            </div>
                                            <div className='col-3 mb-2'>
                                                <label className='w-100'>Weight Image</label>
                                                <input onChange={e => fileHandling(e, index)} type='file' name='weight_photo' className='form-control cus-file-upload' />
                                            </div>
                                            <div className='col-3 mb-2'>
                                                <label className='w-100'>Purity Photo</label>
                                                <input onChange={e => fileHandling(e, index)} type='file' name='purity_photo' className='form-control cus-file-upload' />
                                            </div> */}
                                            <div className='col-md-3 col-12 mb-2'>
                                                <label className='w-100'>Other Documents</label>
                                                <ButtonDropdown className='mb-2'>
                                                    <ButtonDropdown.Toggle className='btn btn-orange-primary-outline text-orange'>
                                                        Upload
                                                    </ButtonDropdown.Toggle>
                                                    <ButtonDropdown.Menu>
                                                        <ButtonDropdown.Item onClick={e => {
                                                            setCameraUploadFile('supporting_documents');
                                                            setCameraUploadFileInx(index);
                                                            dispatch(setShowCamera(true));
                                                        }}>Capture from camera</ButtonDropdown.Item>
                                                        <ButtonDropdown.Item onClick={e => { triggerFileSDInput(index) }}>

                                                            Upload from your computer</ButtonDropdown.Item>
                                                    </ButtonDropdown.Menu>
                                                </ButtonDropdown>
                                                <input accept={fileAccept} ref={el => fileSD.current[index] = el} className='d-none' onChange={e => fileHandling(e, index)} name='supporting_documents' type="file" multiple />
                                                <div className='row'>
                                                    <MultipleFilePreview files={item.supporting_documents} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            </div>
                        ))}
                        <div className='form-group text-end mt-2'>
                            <button className='btn btn-orange-primary'
                                onClick={addItem}
                            >
                                <IconPlus customClass='text-white' /> Add Metal/Gem
                            </button>
                        </div>



                        <div className='row mt-5'>
                            <div className="col-6 mt-3">
                                <div className="form-group">
                                    <button
                                        className="btn  btn-purple-primary-outline"
                                        type='button'
                                        onClick={e => setCurrentStep(currentStep - 1)}
                                    >
                                        Back
                                    </button>
                                </div>
                            </div>
                            {productItems.length > 0 && (
                                <div className="col-6 mt-3 text-end">
                                    <div className="form-group">
                                        <button
                                            className="btn  btn-purple-primary"
                                            type='button'
                                            onClick={e => gotoNextStep()}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div >
                    </div>
                )}
                {/* STEP 2 END */}

                {/* STEP 3 START */}
                {currentStep === 3 && (
                    <div className='col-12 mt-5'>

                        <div className='row'>

                            <div className='col-md-3 col-12'>
                                <div className='form-group'>
                                    <label>Wallet Contribution</label>
                                </div>
                                <div className='form-group mb-2'>
                                    <label>Customer Contribution</label>
                                    <Dropdown
                                        value={formData.customer_contribution}
                                        options={[{ id: 10, name: '10%' }, { id: 15, name: '15%' }, { id: 20, name: '20%' }, { id: 25, name: '25%' }, { id: 30, name: '30%' }]}
                                        optionLabel="name"
                                        optionValue="id"
                                        placeholder="Select a contribution"
                                        className="w-100 custom-single-dropdown"
                                        name='customer_contribution'
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='form-group mb-2'>
                                    <label className='w-100'>Madini Chapaa Contribution</label>
                                    <label className='form-control'>15%</label>
                                </div>
                            </div>
                            <div className='col-md-9 col-12'>
                                <div className='form-group'>
                                    <label>Estimated Value</label>
                                </div>
                                <ItemDetails
                                    items={productItems}
                                    cc={formData.customer_contribution}
                                    mc={formData.mc_contribution}
                                />
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <div className="col-6 mt-3">
                                <div className="form-group">
                                    <button
                                        className="btn  btn-purple-primary-outline"
                                        type='button'
                                        onClick={e => setCurrentStep(currentStep - 1)}
                                    >
                                        Back
                                    </button>
                                </div>
                            </div>
                            <div className="col-6 mt-3 text-end">
                                <div className="form-group">
                                    <button
                                        className="btn  btn-purple-primary"
                                        type='button'
                                        onClick={e => setCurrentStep(currentStep + 1)}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/* STEP 3 END */}

                {/* STEP 4 START */}
                {currentStep === 4 && (
                    <div className='col-12 mt-5'>
                        <div className='row'>
                            <div className="col-md-12">
                                <h6><strong>Assignee</strong></h6>
                                <hr />
                            </div>
                            <div className="col-md-3">
                                <div className="form-group mb-3">
                                    <label>Organisation</label>
                                    {
                                        parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.ORGANIZATION ||
                                            parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.AGENT ? (
                                            <Dropdown
                                                disabled={true}
                                                value={formData.organization_id}
                                                options={organizationList}
                                                optionLabel="organization_name"
                                                optionValue="id"
                                                placeholder="Select a organisation"
                                                className="w-100 custom-single-dropdown"
                                                name='organization_id'
                                            />
                                        ) : (
                                            <Dropdown
                                                value={formData.organization_id}
                                                onChange={handleChange}
                                                options={organizationList}
                                                optionLabel="organization_name"
                                                optionValue="id"
                                                placeholder="Select a organisation"
                                                className="w-100 custom-single-dropdown"
                                                name='organization_id'
                                            />
                                        )
                                    }
                                    {errors.organization_id && <span className="error-message">{errors.organization_id}</span>}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group mb-3">
                                    <label>Agent</label>
                                    {
                                        parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.AGENT ? (
                                            <Dropdown
                                                disabled={true}
                                                value={formData.agent_id}
                                                options={agentList}
                                                optionLabel="full_name"
                                                optionValue="id"
                                                placeholder="Select a agent"
                                                className="w-100 custom-single-dropdown"
                                                name='agent_id'
                                            />
                                        ) : (
                                            <Dropdown
                                                value={formData.agent_id}
                                                onChange={handleChange}
                                                options={agentList}
                                                optionLabel="full_name"
                                                optionValue="id"
                                                placeholder="Select a agent"
                                                className="w-100 custom-single-dropdown"
                                                name='agent_id'
                                            />
                                        )
                                    }


                                    {errors.agent_id && <span className="error-message">{errors.agent_id}</span>}
                                </div>
                            </div>
                            <div className="col-md-12 mt-5">
                                <h6><strong>Pick up schedule</strong></h6>
                                <hr />
                            </div>
                            <div className="col-md-3">
                                <div className="form-group mb-3">
                                    <label>Pick-up Date</label>
                                    <Calendar
                                        value={formData.scheduled_date ? new Date(formData.scheduled_date) : ''}
                                        onChange={handleChange}
                                        dateFormat="dd/mm/yy"
                                        name="scheduled_date"
                                        className='date-picker-input'
                                        minDate={new Date()}
                                        showIcon
                                    />

                                    {errors.scheduled_date && <span className="error-message">{errors.scheduled_date}</span>}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group mb-3">
                                    <label>Pick-up Time</label>
                                    <Calendar
                                        value={formData.scheduled_time ? new Date(formData.scheduled_time) : ''}
                                        onChange={handleChange}
                                        name="scheduled_time"
                                        className="date-picker-input"
                                        showIcon
                                        timeOnly
                                        icon={() => <IconTime />}
                                        showTime
                                        hourFormat="12"
                                        maxDate={(() => {
                                            const endTime = new Date();
                                            endTime.setHours(21, 0, 0, 0); // Maximum time: 9:00 PM
                                            return endTime;
                                        })()}
                                    />
                                    {errors.scheduled_time && (
                                        <span className="error-message">{errors.scheduled_time}</span>
                                    )}
                                </div>
                            </div>

                        </div>
                        <div className='row mt-5'>
                            <div className="col-6 mt-3">
                                <div className="form-group">
                                    <button
                                        className="btn  btn-purple-primary-outline"
                                        type='button'
                                        onClick={e => setCurrentStep(currentStep - 1)}
                                    >
                                        Back
                                    </button>
                                </div>
                            </div>
                            <div className="col-6 mt-3 text-end">
                                <div className="form-group">

                                    <button
                                        className="btn  btn-purple-primary"
                                        type='button'
                                        onClick={e => gotoNextStep()}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                )}
                {/* STEP 4 END */}

                {/* STEP 5 START */}
                {currentStep === 5 && (
                    <div className='col-12 mt-5'>
                        <div className='row'>
                            <div className='col-12 trade-customer-section mb-3'>
                                {selectedCustomer && (<>
                                    <div className='row'>
                                        <div className='col-md-4 col-12'>
                                            <div className='row'>
                                                <div className='col-3 align-self-center'>
                                                    {customerProfile ? (
                                                        <div className='placeholder-image-bg'>
                                                            <img src={`data:${customerProfile?.type};base64,${customerProfile?.base64}`} />
                                                        </div>
                                                    ) : (
                                                        <div className='placeholder-image-bg'>
                                                            <img src={`${ENVIRONMENT.BASE_URL}assets/images/profile.png`} />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='col-9 align-self-center'>
                                                    <p className='m-0'><strong>Customer ID: {selectedCustomer.row_ref_number}</strong></p>
                                                    <p className='m-0 gray-color'>{selectedCustomer.first_name} {selectedCustomer.middle_name} {selectedCustomer.last_name}</p>
                                                    <p className='m-0 gray-color'>{selectedCustomer.email}</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Location</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.location_name}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>National Identity Number</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.national_id}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Serial Number</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.serial_number}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Phone Number</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.phone_number}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Gender</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.gender}</label>
                                            </div>
                                        </div>

                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Citizenship</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.citizen_ship}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Date of Birth</label>
                                                <label className='w-100 gray-color'>{getDisplayDateOnly(selectedCustomer.dob)}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Marital Status</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.marital_status}</label>
                                            </div>
                                        </div>


                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Next of kin Name</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.nominee_name}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Next of kin Relationship</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.nominee_relationship}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Next of kin Phone Number</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.nominee_phone_number}</label>
                                            </div>
                                        </div>
                                        {/* <div className='col-4'>
                                            <div className='form-group'>
                                                <label className='w-100'>Remarks</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.remarks}</label>
                                            </div>
                                        </div> */}
                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Occupation</label>
                                                <label className='w-100 gray-color'>{selectedCustomer.occupation}</label>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <div className='form-group'>
                                                <label className='w-100'>Address</label>
                                                <label className='w-100 gray-color'>
                                                    {formatAddress(selectedCustomer.address, 50).split('\n').map((line, index) => (
                                                        <React.Fragment key={index}>
                                                            {line}
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </>)}
                            </div>
                            <hr />
                            <p className='item-header'><strong>Assignee</strong></p>
                            <div className='col-4 mb-4'>
                                <div className='form-group'>
                                    <label className='w-100'>Organisation</label>
                                    <label className='w-100 gray-color'>{organizationList && formData.organization_id && organizationList.map(org => org.id === formData.organization_id && (org.organization_name))}</label>
                                </div>
                            </div>
                            <div className='col-4 mb-4'>
                                <div className='form-group'>
                                    <label className='w-100'>Agent</label>
                                    <label className='w-100 gray-color'>{agentList && formData.agent_id && agentList.map(agent => agent.id === formData.agent_id && (agent.full_name))}</label>
                                </div>
                            </div>
                            <hr />
                            <p className='item-header'><strong>Pick up schedule</strong></p>
                            <div className='col-4 mb-4'>
                                <div className='form-group'>
                                    <label className='w-100'>Pick-up Date</label>
                                    <label className='w-100 gray-color'>{getDisplayDateOnly(formData.scheduled_date)}</label>
                                </div>
                            </div>
                            <div className='col-4 mb-4'>
                                <div className='form-group'>
                                    <label className='w-100'>Pick-up Time</label>
                                    <label className='w-100 gray-color'>{getDisplayTimeOnly(formData.scheduled_time)}</label>
                                </div>
                            </div>
                            <div className='clearfix'></div>
                        </div>
                        <hr />

                        <p className='item-header'><strong>Charges</strong></p>
                        <ItemDetails
                            items={productItems}
                            cc={formData.customer_contribution}
                            mc={formData.mc_contribution}
                        />

                        <div className='row mt-5'>
                            <div className="col-md-6 col-5 mt-3">
                                <div className="form-group">
                                    <button
                                        className="btn  btn-purple-primary-outline"
                                        type='button'
                                        onClick={e => setCurrentStep(currentStep - 1)}
                                    >
                                        Back
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-6 col-7 mt-3 text-end">
                                <div className="form-group">

                                    <button
                                        className="btn  btn-purple-primary"
                                        type='button'
                                        onClick={createApplication}
                                    >
                                        {disabledButton ? <Spinner /> : null} Submit Application
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/* STEP 5 END */}
            </div >
            {/* step content end */}
            <CameraUpload onImgCallBack={handleCapture} />
            <FilePreview />
            <VerifyOtp trade={true} />
        </>
    );
};


export default TradeingFormModel;
